
import Vue from 'vue';

import ProtocolsList from '@/components/ProtocolsList.vue';
import PartnersList from '@/components/PartnersList.vue';

export default Vue.extend({
  name: 'Home',

  components: {
    ProtocolsList,
    PartnersList,
  },

  data: () => ({}),
});
