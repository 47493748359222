import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/views/Home.vue';
import Protocols from '@/views/Protocols.vue';
import AboutUs from '@/views/AboutUs.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about-us',
    name: 'About us',
    component: AboutUs,
  },
  {
    path: '/protocols',
    name: 'Protocols',
    component: Protocols,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
