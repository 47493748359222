
import Vue from 'vue';

export default Vue.extend({
  name: 'AboutUs',

  components: {
    //
  },

  data: () => ({
    teamMembers: [
      {
        image: 'seb.png',
        name: 'Sebastian Heyden',
        title: 'Co-founder',
        twitter: 'czarly',
        description: `
          I have always been fascinated by the notion of programming freedom.
            My urge to use technology to cross boundaries and challenge business models has led
            me to filesharing networks and crypto currencies.
        `,
      },
      {
        image: 'payne.jpg',
        name: 'Alexandru Baboi',
        title: 'Co-founder',
        twitter: 'Indexer_Payne',
        description: `
         Blockchain research enthusiast, ex-crypto trader turned to the tech side because they had cookies.
        `,
      },
    ],
  }),

  methods: {
    imagePath(url: string): NodeRequire {
      return require(`../assets/${url}`);
    },
  },
});
