
import Vue from 'vue';

import ProtocolsList from '@/components/ProtocolsList.vue';

export default Vue.extend({
  name: 'Protocols',

  components: {
    ProtocolsList,
  },

  data: () => ({}),
});
