
import Vue from "vue";

import Nav from "@/components/core/Nav.vue";
import Footer from "@/components/core/Footer.vue";

export default Vue.extend({
  name: "App",

  components: {
    Nav,
    Footer,
  },

  data: () => ({
    //
  }),
});
