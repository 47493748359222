
import Vue from "vue";

export default Vue.extend({
  name: "Nav",

  data: () => ({
    activeTab: "home",
    navbarItems: [
      { name: "Home", slug: "home", path: "/" },
      { name: "About Us", slug: "about-us", path: "/about-us" },
      // { name: 'Protocols', slug: 'protocols', path: '/protocols' },
    ],
    drawer: false,
  }),

  methods: {
    setActiveTab(tab: string) {
      this.activeTab = tab;
    },
  },
});
